import React, { useEffect } from 'react';
import { useWallet } from 'contexts/Wallet';
import { toast } from 'react-toastify';
import useSwitch from 'hooks/useSwitch';

import classes from 'components/NavBar/NavBar.module.scss';
import { APP_CONFIG, ContractAddress } from 'config';

export const NavBar: React.FC = () => {
  const { account, connect, disconnect, isConnectedToAllowedNetwork, refresh } = useWallet();
  const connectedToPolygon = useSwitch(true);

  useEffect(() => {
    const checkConnection = async () => {
      const isConnectedToPolygon = await isConnectedToAllowedNetwork();
      connectedToPolygon.set(isConnectedToPolygon);
    };
    checkConnection();
  }, [refresh.triggerValue]);

  const addTokenToWallet = async () => {
    const isConnectedToPolygon = await isConnectedToAllowedNetwork();
    if (!isConnectedToPolygon) {
      toast.error('Please connect to the Polygon Chain');
      return;
    }

    try {
      await (window as any).ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: ContractAddress.Token,
            symbol: 'D11',
            decimals: 18,
            image: 'https://defieleven.com/img/D11-logo.jpeg',
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  async function switchNetwork() {
    if (!(window as any).ethereum) return;
    try {
      (window as any).ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${Number(137).toString(16)}`,
            chainName: 'Polygon Mainnet',
            nativeCurrency: {
              name: 'Matic',
              symbol: 'MATIC',
              decimals: 18,
            },
            rpcUrls: ['https://polygon-rpc.com'],
            blockExplorerUrls: ['https://polygonscan.com'],
          },
        ],
      });
    } catch (e) {
      toast.error('Please switch to the Polygon network');
    }
  }

  return (
    <div className={classes.navbar}>
      <img src={APP_CONFIG.COMPANY.LOGO} alt={`${APP_CONFIG.COMPANY.NAME} Logo`} />
      <div>
        {account ? (
          <div className={classes.connected}>
            {!connectedToPolygon.value && (
              <div className={classes.switchToPolygonButton} onClick={switchNetwork}>
                <p>Switch to Polygon</p>
              </div>
            )}
            <div className={classes.button} onClick={addTokenToWallet}>
              <p>Add $D11 to Metamask</p>
            </div>
            <div className={classes.button} onClick={disconnect}>
              <p>Disconnect Wallet</p>
            </div>
            <div className={classes.walletAddress}>
              <p>{`${account.substr(0, 5)}...${account.substr(account.length - 5, account.length)}`}</p>
            </div>
          </div>
        ) : (
          <div className={classes.connected}>
            {!connectedToPolygon.value && (
              <div className={classes.switchToPolygonButton} onClick={switchNetwork}>
                <p>Switch to Polygon</p>
              </div>
            )}
            <div className={classes.button} onClick={connect}>
              <p>Connect to a Wallet</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
