import { PoolDuration } from 'App';
import clsx from 'clsx';
import React from 'react';
import classes from './Tabs.module.scss';

const PoolTabs: React.FC<{
  selectedPool: PoolDuration;
  setSelectedPool: (pool: PoolDuration) => void;
}> = ({ setSelectedPool, selectedPool }) => {
  return (
    <div className={classes.main}>
      {['30_Days', '90_Days', '150_Days'].map((pool, index) => {
        return (
          <div
            key={index}
            className={clsx(classes.tab, {
              [classes.selected]: selectedPool === pool,
            })}
            onClick={() => setSelectedPool(pool as PoolDuration)}>
            {pool.replace('_', ' ')}
          </div>
        );
      })}
    </div>
  );
};

export default PoolTabs;
