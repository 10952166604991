import classes from 'App.module.scss';

// hooks
import { useState, useEffect, useCallback } from 'react';
import { useWallet } from 'contexts/Wallet';

// libraries
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { APP_CONFIG } from 'config';

// components
import { NavBar } from 'components/NavBar';
import Card from 'components/Card';
import PoolTabs from 'components/Tabs';
import Loader from 'components/Loader';

// helpers
import Web3 from 'helpers/web3';
import Contracts from 'helpers/contracts';
import { getDate } from 'helpers/utils';

export type PoolDuration = '30_Days' | '90_Days' | '150_Days';

function App() {
  const { account, isConnectedToAllowedNetwork, refresh } = useWallet();

  const [stakingBalance, setStakingBalance] = useState('0.00');
  const [stakedAmount, setStakedAmount] = useState('0.00');
  const [earnedBalance, setEarnedBalance] = useState('0.00');
  const [stakingPeriod, setStakingPeriod] = useState('0.00');
  const [rewardDate, setRewardDate] = useState<string | number>();
  const [stakingTill, setStakingTill] = useState<string | number>();
  const [stakingCap, setStakingCap] = useState<number>(0);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);

  const [isStakingMode, setIsStakingMode] = useState(true);
  const [stakingWindowOpen, setStakingWindowOpen] = useState(true);

  const [selectedPool, setSelectedPool] = useState<PoolDuration>('90_Days');

  useEffect(() => {
    if (rewardDate) {
      if (Date.now() > rewardDate) {
        setIsStakingMode(false);
      } else {
        setIsStakingMode(true);
      }
    }
  }, [rewardDate]);

  useEffect(() => {
    if (stakingTill) {
      if (Date.now() > stakingTill) {
        setStakingWindowOpen(false);
      } else {
        setStakingWindowOpen(true);
      }
    }
  }, [stakingTill]);

  const resetUserData = () => {
    setStakingBalance('0.00');
    setStakedAmount('0.00');
    setEarnedBalance('0.00');
  };

  const resetCommonData = () => {
    setRewardDate(getDate(new Date(0)));
    setStakingPeriod('0');
  };

  const fetchUserData = useCallback(async () => {
    if (!(await isConnectedToAllowedNetwork())) return resetUserData();

    const web3 = Web3.instance;
    const contracts = Contracts.instances;

    try {
      setIsLoadingDetails(true);
      const [_stakingBalance, _stakedAmount, _earnedBalance] = await Promise.all([
        await web3.utils.fromWei(await contracts.Token.methods.balanceOf(account).call()),
        await web3.utils.fromWei(
          await contracts[`StakingRewards_${selectedPool}`].methods.balanceOf(account).call(),
        ),
        await web3.utils.fromWei(
          await contracts[`StakingRewards_${selectedPool}`].methods.earned(account).call(),
        ),
      ]);
      setStakingBalance(parseFloat(_stakingBalance).toFixed(2));
      setStakedAmount(parseFloat(_stakedAmount).toFixed(2));
      setEarnedBalance(parseFloat(_earnedBalance).toFixed(2));

      setIsLoadingDetails(false);
    } catch (err) {
      setIsLoadingDetails(false);
      console.error(err);
      toast.error('Something went wrong while fetching data.');
    }
  }, [account, isConnectedToAllowedNetwork, selectedPool]);

  const fetchCommonData = useCallback(async () => {
    if (!(await isConnectedToAllowedNetwork())) return resetCommonData();

    try {
      setIsLoadingDetails(true);
      const contracts = Contracts.instances;
      const _stakingPeriod =
        parseInt(await contracts[`StakingRewards_${selectedPool}`].methods.rewardsDuration().call()) / 86400;
      const _rewardDate =
        parseInt(await contracts[`StakingRewards_${selectedPool}`].methods.periodFinish().call()) * 1000;

      const _stakingTill =
        parseInt(await contracts[`StakingRewards_${selectedPool}`].methods.stakingTill().call()) * 1000;

      const _stakingCap = Web3.instance.utils.fromWei(
        await contracts[`StakingRewards_${selectedPool}`].methods.stakingCap().call(),
      );

      setStakingCap(parseInt(_stakingCap));
      setRewardDate(_rewardDate);
      setStakingTill(_stakingTill);
      setStakingPeriod(_stakingPeriod.toFixed(2));
      setIsLoadingDetails(false);
    } catch (err) {
      setIsLoadingDetails(false);
      console.error(err);
      toast.error('Something went wrong while fetching data.');
    }
  }, [isConnectedToAllowedNetwork, selectedPool]);

  useEffect(() => {
    fetchCommonData();
    account ? fetchUserData() : resetUserData();
  }, [account, fetchCommonData, fetchUserData, refresh.triggerValue, selectedPool]);

  if (isMobile) {
    return (
      <div className={classes.switchToDesktop}>
        <img src={APP_CONFIG.COMPANY.LOGO} alt={`${APP_CONFIG.COMPANY.NAME} Logo`} />
        <p>Please Switch to a Desktop Device</p>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {isLoadingDetails && <Loader />}
      <NavBar />
      <div className={classes.content}>
        <PoolTabs selectedPool={selectedPool} setSelectedPool={setSelectedPool} />

        <Card
          stakingBalance={stakingBalance}
          stakedAmount={stakedAmount}
          earnedBalance={earnedBalance}
          stakingPeriod={stakingPeriod}
          rewardDate={rewardDate as string | number}
          stakingMode={isStakingMode}
          selectedPool={selectedPool}
          setSelectedPool={setSelectedPool}
          stakingCap={stakingCap}
          stakingWindowOpen={stakingWindowOpen}
        />
      </div>
    </div>
  );
}

export default App;
