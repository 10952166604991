import React from 'react';
import ReactDOM from 'react-dom';

import Spinner from 'components/Spinner';

const Loader = () => {
  return ReactDOM.createPortal(
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 99999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Spinner />
    </div>,
    document.getElementById('modal') as Element,
  );
};

export default Loader;
