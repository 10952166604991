// hooks
import useInput from 'hooks/useInput';
import { useWallet } from 'contexts/Wallet';
import useSwitch from 'hooks/useSwitch';

// libraries
import { toast } from 'react-toastify';
import BN from 'bn.js';

// components
import Spinner from '../Spinner';

// helpers
import Web3 from 'helpers/web3';
import Contracts from 'helpers/contracts';
import { getDate } from 'helpers/utils';

// styles
import classes from 'components/Card/Card.module.scss';

// config
import { APP_CONFIG, ContractAddress } from 'config';
import { validateAmount } from 'helpers/validate';
import { PoolDuration } from 'App';
import clsx from 'clsx';

type Props = {
  stakingBalance: string;
  stakedAmount: string;
  earnedBalance: string;
  stakingPeriod: string;
  rewardDate: string | number;
  stakingMode: boolean;
  selectedPool: PoolDuration;
  setSelectedPool: (pool: PoolDuration) => void;
  stakingCap: number;
  stakingWindowOpen: boolean;
};

const StakeCard: React.FC<Props> = ({
  stakingBalance,
  stakedAmount,
  earnedBalance,
  stakingPeriod,
  rewardDate,
  stakingMode,
  selectedPool,
  stakingCap,
  setSelectedPool,
  stakingWindowOpen,
}) => {
  const { account, isConnectedToAllowedNetwork, refresh } = useWallet();
  const stakingAmount = useInput();
  const stakingInProcess = useSwitch();
  const withdrawalInProcess = useSwitch();

  const onStake = async () => {
    if (!account) {
      return toast.info('Please connect your wallet');
    }

    if (!(await isConnectedToAllowedNetwork())) {
      return toast.info('Please connect to one of the supported chains');
    }

    if (!validateAmount(stakingAmount.value)) {
      return;
    }

    if (parseFloat(stakingAmount.value) > parseFloat(stakingBalance)) {
      return toast.info('Insufficient Balance');
    }

    try {
      const web3 = Web3.instance;
      const _stakingAmount = web3.utils.toWei(stakingAmount.value);
      const contracts = Contracts.instances;

      const totalSupply = await contracts[`StakingRewards_${selectedPool}`].methods.totalSupply().call();
      const stakingCap = await contracts[`StakingRewards_${selectedPool}`].methods.stakingCap().call();

      if (new BN(_stakingAmount).add(new BN(totalSupply)).gt(new BN(stakingCap))) {
        return toast.error('Staking cap exceeded');
      }

      stakingInProcess.true();
      const allowance = await contracts.Token.methods
        .allowance(account, ContractAddress[`StakingRewards_${selectedPool}`])
        .call();
      if (new BN(_stakingAmount).gt(new BN(allowance))) {
        await contracts.Token.methods
          .approve(ContractAddress[`StakingRewards_${selectedPool}`], _stakingAmount)
          .send({ from: account });
      }
      await contracts[`StakingRewards_${selectedPool}`].methods.stake(_stakingAmount).send({ from: account });
      toast.success('Staked Successfully');
      stakingAmount.reset();
      refresh.rerender();
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong');
    }
    stakingInProcess.false();
  };

  const onWithdraw = async () => {
    if (!account) {
      return toast.info('Please connect to your wallet');
    }

    if (!(await isConnectedToAllowedNetwork())) {
      return toast.info('Please connect to one of the supported chains', { theme: 'colored' });
    }

    if (earnedBalance === '0.00') {
      return toast.error('Already withdrawn or did not participate');
    }

    try {
      withdrawalInProcess.true();
      const contracts = Contracts.instances;
      await contracts[`StakingRewards_${selectedPool}`].methods.exit().send({ from: account });
      toast.success('Withdrawal successful');
      refresh.rerender();
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong');
    }
    withdrawalInProcess.false();
  };

  return (
    <div className={classes.card}>
      <div>
        <div className={classes.heading}>{stakingMode ? 'Stake' : 'Staking has ended'}</div>
        <span className={classes.apr}>
          APR :{' '}
          {selectedPool === '30_Days'
            ? '50.70%'
            : selectedPool === '90_Days'
            ? '127%'
            : selectedPool === '150_Days'
            ? '134%'
            : null}
        </span>
        <div className={classes.center}>
          {stakingMode ? (
            <div className={classes.inputWrapper}>
              {stakingWindowOpen ? (
                <>
                  <div className={classes.input}>
                    <input
                      type="number"
                      min={0}
                      value={stakingAmount.value}
                      onChange={stakingAmount.set}
                      placeholder="Amount"
                      pattern="[0-9]"
                      disabled={stakingInProcess.value || !stakingWindowOpen}
                    />
                    <button onClick={onStake} disabled={stakingInProcess.value}>
                      {stakingInProcess.value ? <Spinner /> : 'Stake'}
                    </button>
                  </div>
                  <div className={classes.balance}>
                    <span>Your total</span>{' '}
                    <strong>
                      {APP_CONFIG.STAKING_TOKEN}: {stakingBalance}
                    </strong>
                  </div>
                </>
              ) : (
                <>
                  <button className={clsx(classes.withdrawButton, classes.disabled)}>
                    Staking Window has Closed
                  </button>

                  <div className={classes.balance}>
                    <span>Your total</span>{' '}
                    <strong>
                      {APP_CONFIG.STAKING_TOKEN}: {stakingBalance}
                    </strong>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className={classes.inputWrapper}>
              <button
                onClick={onWithdraw}
                disabled={withdrawalInProcess.value}
                className={classes.withdrawButton}>
                {withdrawalInProcess.value ? <Spinner /> : 'Withdraw'}
              </button>

              {(selectedPool === '30_Days' || selectedPool === '90_Days') && (
                <button
                  onClick={() => {
                    setSelectedPool(selectedPool === '30_Days' ? '90_Days' : '150_Days');
                  }}
                  className={classes.withdrawButton}
                  style={{ marginTop: '1rem' }}>
                  Stake Now
                </button>
              )}

              <div className={classes.balance}>
                <span>Your total</span>{' '}
                <strong>
                  {APP_CONFIG.STAKING_TOKEN}: {stakingBalance}
                </strong>
              </div>
            </div>
          )}
        </div>

        <div className={classes.stats}>
          <div className={classes.stat}>
            <span>Currently Staked:</span>
            <strong>
              {stakedAmount} {APP_CONFIG.STAKING_TOKEN}
            </strong>
          </div>
          <div className={classes.stat}>
            Staking Period: <strong>{stakingPeriod} Days</strong>
          </div>
        </div>
        <div className={classes.stats}>
          <div className={classes.stat}>
            Reward Earned:{' '}
            <strong>
              {earnedBalance} {APP_CONFIG.REWARD_TOKEN}
            </strong>
          </div>
          <div className={classes.stat}>
            Reward Date: <strong>{getDate(new Date(rewardDate))}</strong>
          </div>
        </div>
        <div className={classes.stats}>
          <div className={classes.stat}>
            Staking Cap: <strong>{stakingCap}</strong>
          </div>
        </div>
        <div className={classes.note}>
          <strong>Note: </strong>
          The staked token will be locked for the whole duration of the program. Only after the reward date{' '}
          {getDate(new Date(rewardDate))}, you can withdraw the total staked amount of token + the staking
          rewards to your wallet in {APP_CONFIG.REWARD_TOKEN}.
        </div>
      </div>
    </div>
  );
};

export default StakeCard;
