import { abi as TokenABI } from 'abis/IERC20.json';
import { abi as StakingRewardsABI } from 'abis/StakingRewards.json';
import { IERC20 } from 'types/IERC20';
import { StakingRewards } from 'types/StakingRewards';
import { AllowedChainConfig, ContractConfig } from 'types/config';
import logo from 'assets/Logo.png';

export const APP_CONFIG = {
  COMPANY: {
    NAME: 'Defi 11',
    LOGO: logo,
  },
  STAKING_TOKEN: '$D11',
  REWARD_TOKEN: '$D11',
};

export const allowedChains: AllowedChainConfig[] = [
  // {
  //   id: 80001,
  //   name: 'Polygon Testnet',
  // },
  {
    id: 137,
    name: 'Polygon Mainnet',
  },
];

export const ContractAddress = {
  Token: '0xc58158c14d4757ef36ce25e493758f2fceedec5d',
  StakingRewards_30_Days: '0x8bB039e83f3Bda2ab5BF5317e91555BDaAf0fbf7',
  StakingRewards_90_Days: '0x8e2bC2fAAF45a5704067518f3475F29b960c45F6',
  StakingRewards_150_Days: '0x2cCD63c9802813d50C3Aa37Fdb8aC6932d543d8c',
};

export const contracts: ContractConfig[] = [
  {
    name: 'Token',
    abi: TokenABI,
    address: ContractAddress.Token,
  },
  {
    name: 'StakingRewards_30_Days',
    abi: StakingRewardsABI,
    address: ContractAddress.StakingRewards_30_Days,
  },
  {
    name: 'StakingRewards_90_Days',
    abi: StakingRewardsABI,
    address: ContractAddress.StakingRewards_90_Days,
  },
  {
    name: 'StakingRewards_150_Days',
    abi: StakingRewardsABI,
    address: ContractAddress.StakingRewards_150_Days,
  },
];

export interface ContractInstances {
  Token: IERC20;
  StakingRewards_30_Days: StakingRewards;
  StakingRewards_90_Days: StakingRewards;
  StakingRewards_150_Days: StakingRewards;
}
